import React, { Component, useEffect } from "react"
import Box from "@mui/material/Box"
import DataTable from "react-data-table-component"
import { Column } from "primereact/column"
import { useState } from "react"
import { GetBetsPerAgent, GetBets } from "../../services/AcceptedBetsServices"
import Button from "@mui/material/Button"
import Modal from "react-bootstrap/Modal"
import { useGlobalState, setGlobalState } from "../../helpers/state"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Await, Navigate } from "react-router-dom"
import { InputLabel, MenuItem, Select } from "@mui/material"
import { Print, PrintDisabled } from "@mui/icons-material"
import Loading from "../../img/spinner.gif"
import * as moment from "moment"
import FormControl from "@mui/material/FormControl"
import ThermalPrinterEncoder from "thermal-printer-encoder"

const AcceptedBetsCeb = () => {
  let encoder = new ThermalPrinterEncoder({
    language: "esc-pos",
  })

  const [QrRef, setQrRef] = useState("")
  let user = localStorage.getItem("usercode")
  const [PrinterName, setPrinterName] = useGlobalState("printername")
  const MySwal = withReactContent(Swal)

  var year = moment().format("yyyy")

  var version = localStorage.getItem("version")
  var key = localStorage.getItem("keycode")
  const addNextLine = () => {
    if (version == "v8.14") {
      return Feed_LINE
    } else {
      return ""
    }
  }
  var todayDate = moment().format("yyyy-MM-DD")
  // var todayDateNewFormat = moment().format("MMM DD, YYYY hh:mm A")
  var todayDateNewFormat = moment().format("DD-MMM-YYYY HH:mm:ss")
  let Reprintedheader = () => {
    // if (
    //   version == "v8.14" ||
    //   version == "v8.17" ||
    //   version == "v8.18" ||
    //   version == "v8.19"
    // ) {
    return Feed_LINE + "========THIS IS A REPRINT=======" + Feed_LINE
    // } else {
    //   return Feed_LINE + "   ==== This is a reprint ====" + Feed_LINE
    // }
  }

  let ReprintedBottom = () => {
    // if (
    //   version == "v8.14" ||
    //   version == "v8.17" ||
    //   version == "v8.18" ||
    //   version == "v8.19"
    // ) {
    return Feed_LINE + "========THIS IS A REPRINT======="
    // } else {
    //   return (
    //     Feed_LINE +
    //     Feed_LINE +
    //     "   ==== This is a reprint ====" +
    //     Feed_LINE +
    //     Feed_LINE +
    //     Feed_LINE
    //   )
    // }
  }

  const QrSize = () => {
    let size = 0
    switch (PrinterName) {
      case "PT-210":
        size = 1
        break
      case "PT-200":
        size = 5
        break
      default:
        size = 1
    }
    return size
  }
  const qrref = async () => {
    let qrstring = encoder
      .size("normal")
      .line("")
      .size("small")
      .raw([0x1b, 0x33, 0x00])
      .text("")
      .align(localStorage.getItem("qrposition").toLowerCase())
      .text(encoder.qrcode(QrRef, 2, QrSize(), "l"))
      .size("normal")
      .raw([0x1b, 0x33, 10])
      .newline()
      .newline()
      .newline()
      .align("left")
      .encode()
    return printCharacteristic2.writeValue(qrstring).then(async () => {})
  }
  // Many options with Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat("en", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    // second: "2-digit",
  })
  // let time = moment.format()

  // let Feed_LINE = "\u000D\u000A"
  let Feed_LINE = new TextDecoder().decode(new Uint8Array([10]))
  let Bold_str = new TextDecoder().decode(new Uint8Array([0x1b, 0x21, 0x08]))
  let normal_str = new TextDecoder().decode(new Uint8Array([0x1b, 0x21, 0x00]))

  const [printCharacteristic2, setPrintCharacteristic2] = useGlobalState(
    "printCharacteristic"
  )
  const [acceptedbets, setAcceptedbets] = useState([])
  const [betsclick, setBetsclick] = useState([])
  const [betstoprint, setBetstoPrint] = useState([])
  const [selectedtrans, setSelectedTrans] = useState(null)
  const [selectedtime, setSelectedTime] = useState(null)
  const [modalShow, setModalShow] = useState(false)
  const [totalgross, setTotalGross] = useState(0)
  const [triger, setTriger] = useState(null)
  const drawtime = ["All", "2PM", "5PM", "9PM"]
  const [timeselected, setTimeSelected] = useState("All")

  const betscolumns = [
    {
      name: "Drawcode",
      selector: (row) => row.drawcode,
    },
    {
      name: "Combination",
      selector: (row) => row.combination,
    },
    {
      name: "Bet",
      selector: (row) => row.betamount,
    },
  ]

  const columns = [
    {
      name: "Trans ID",
      selector: (row) => row.transactionid,
    },
    {
      name: "Draw Date",
      selector: (row) => row.drawdate,
    },
    {
      name: "Gross",
      selector: (row) => row.totalbet,
    },
  ]

  const Retrunversion = async (key) => {
    // if (
    //   version == "8.20" ||
    //   version == "8.21" ||
    //   version == "8.22" ||
    //   version == "8.23"
    // ) {
    return ""
    // } else {
    //   return Feed_LINE + key
    // }
  }

  const ReturnTotals = async () => {
    // if (
    //   version == "8.20" ||
    //   version == "8.21" ||
    //   version == "8.22" ||
    //   version == "8.23"
    // ) {
    return TotalAmount().totalbetswithamount
    // } else {
    //   return TotalAmount().totalamount + Feed_LINE + TotalAmount().totatbets
    // }
  }
  const sumArray = () => {
    console.log(acceptedbets)
    let total = 0
    acceptedbets.forEach((row) => {
      total += parseInt(row.totalbet)
    })
    setTotalGross(total)
    console.log(total)
  }

  const LoadBets = async (timeselected1) => {
    let data = {
      usercode: localStorage.getItem("usercode"),
      date: todayDate,
      drawtime: timeselected1,
    }
    let result = await GetBetsPerAgent(data)
    setAcceptedbets(result)
    // console.log(result)
    let total = 0
    acceptedbets.forEach(async (row) => {
      total += parseInt(row.totalbet)
      // console.log(row)
    })
    if (result.length != 0) {
      setTotalGross(result[0].Grosstotal)
    }
  }

  const test = async () => {
    // Get the bytes for the text
    let encoder = new TextEncoder("utf-8")
    // Add line feed + carriage return chars to text
    let text = encoder.encode(
      Feed_LINE +
        (await ReturnTotals()) +
        (await Retrunversion(key)) +
        ReprintedBottom()
      // Feed_LINE +
      // Feed_LINE +
      // Feed_LINE +
      // Feed_LINE
    )
    return printCharacteristic2
      .writeValue(text)
      .then(async () => {
        // if (
        //   localStorage.getItem("version") == "v8.14" ||
        //   localStorage.getItem("version") == "v8.17" ||
        //   localStorage.getItem("version") == "v8.18" ||
        //   localStorage.getItem("version") == "v8.19"
        // ) {
        await qrref()
        // }
      })
      .catch((res) => {
        Swal.close()
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <i>Something went wrong, Please try again!</i>,
          icon: "error",
        })
      })
  }

  const BetsPrint = async () => {
    // Get the bytes for the text
    let encoder = new TextEncoder("utf-8")
    // Add line feed + carriage return chars to text
    let text = encoder.encode(
      "Combination  Target      Rambol" + Feed_LINE + BetsToprint()
    )
    return printCharacteristic2.writeValue(text).then(async () => {
      await test()
    })
  }

  const DrawtimeWithCOde = async () => {
    let result = ""
    if (localStorage.getItem("version") == "8.20") {
      result = `Draw Time: ${selectedtime} (${key.replace(".", "")})`
    } else {
      result = `Draw Time: ${selectedtime}`
    }
    return result
  }

  const Reprint = async () => {
    setModalShow(false)
    if (printCharacteristic2 != null) {
      try {
        setBetsclick([])
        setBetstoPrint([])
        betsclick.forEach((row) => {
          Bet3DtoPrint(row.combination, row.drawcode, row.betamount)
        })
        // Get the bytes for the text
        let encoder = new TextEncoder("utf-8")
        // Add line feed + carriage return chars to text
        let text = encoder.encode(
          Feed_LINE +
            `${addSpace(12)}3D Games` +
            Feed_LINE +
            `${addSpace(11)}KD GAMING` +
            Feed_LINE +
            `${addSpace(13)}${localStorage.getItem("version")}` +
            Feed_LINE +
            Feed_LINE +
            "Cashier Code: " +
            localStorage.getItem("usercode") +
            Feed_LINE +
            "Draw Date: " +
            todayDateNewFormat +
            // " " +
            // time +
            Feed_LINE +
            (await DrawtimeWithCOde()) +
            Feed_LINE +
            "Ref. No:" +
            selectedtrans +
            Feed_LINE +
            Reprintedheader() +
            addNextLine()
        )
        return printCharacteristic2
          .writeValue(text)
          .then(async () => {
            await BetsPrint()

            MySwal.fire({
              title: <strong>Success</strong>,
              html: <i>Bets Reprinted!</i>,
              icon: "success",
              timer: 2500,
            })
          })
          .catch((res) => {
            Swal.close()
            MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>Something went wrong, Please try again!</i>,
              icon: "error",
            })
          })
      } catch (error) {
        Swal.close()
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <i>Something went wrong, Please try again!</i>,
          icon: "error",
        })
      }
    } else {
      Swal.close()
      MySwal.fire({
        title: <strong>NOTICE</strong>,
        html: <i>Please connect to the printer!</i>,
        icon: "warning",
      })
    }
  }

  const addSpace = (num) => {
    let string = ""
    for (let i = 0; i <= num; i++) {
      string += " "
    }
    return string
  }
  const BetsToprint = () => {
    let result = ""
    console.log([...betstoprint])
    betstoprint.forEach((row) => {
      console.log(row.target)
      result +=
        row.combination +
        addSpace(11) +
        row.target +
        addSpace(11 - row.target.toString().length) +
        row.rambol +
        Feed_LINE
    })

    return result
  }

  const ConnectToPrinter = () => {
    if (printCharacteristic2 == null) {
      navigator.bluetooth
        .requestDevice({
          filters: [
            {
              services: ["000018f0-0000-1000-8000-00805f9b34fb"],
            },
          ],
        })
        .then((device) => {
          console.log("> Found " + device.name)
          setPrinterName(device.name.split("_")[0])
          console.log("Connecting to GATT Server...")
          Swal.fire({
            title: "Please wait!",
            text: "Connecting to Printer...",
            imageUrl: Loading,
            imageWidth: 200,
            imageHeight: 200,
            allowOutsideClick: false,
            showConfirmButton: false,
          })
          return device.gatt.connect()
        })
        .then((server) =>
          server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
        )
        .then((service) =>
          service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")
        )
        .then((characteristic) => {
          // Cache the characteristic
          setPrintCharacteristic2(characteristic)
          console.log(PrinterName)
          Swal.close()
        })
        .catch(() => {
          setPrintCharacteristic2(null)
          Swal.close()
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <i>Can't Connect to the printer, Please try again!</i>,
            icon: "error",
          })
        })
    } else {
      MySwal.fire({
        title: <strong>NOTICE</strong>,
        html: <i>Your'e already Connected!</i>,
        icon: "info",
      })
    }
  }
  const TotalAmount = () => {
    let TotalAmount = 0
    betsclick.forEach((row) => {
      TotalAmount += parseInt(row.betamount)
    })
    return {
      totalamount: "Total Amount: " + TotalAmount,
      totatbets: "Total Bets: " + betstoprint.length,
      totalbetswithamount:
        "Total Bets/Amount: " +
        betstoprint.length.toString() +
        "/" +
        TotalAmount.toString(),
    }
  }
  const Pinterstat = () => {
    if (printCharacteristic2 != null) {
      return (
        <Button
          color="success"
          sx={{
            m: 1,
            width: "auto",
          }}
          variant="contained"
          startIcon={<Print />}
          onClick={() => ConnectToPrinter()}
        >
          Connected
        </Button>
      )
    } else {
      return (
        <Button
          color="error"
          sx={{
            m: 1,
            width: "auto",
          }}
          variant="contained"
          startIcon={<PrintDisabled />}
          onClick={() => ConnectToPrinter()}
        >
          Disconnected
        </Button>
      )
    }
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedtrans}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={betscolumns}
            data={betsclick}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            fixedHeader
            pagination
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => Reprint()}>
            <i className="pi pi-print" style={{ fontSize: "2em" }}></i>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  //---- check combinations to add 3D onlt ---//
  const CheckcomtoPrint = (Combinationstring) => {
    let result = { bool: false }
    for (let i = 0; i < betstoprint.length; i++) {
      if (
        betstoprint[i].combination == Combinationstring &&
        betstoprint[i].rambol == 0
      ) {
        result = { index: i, bool: true }
        break
      }
    }
    return result
  }

  function createDatatoPrint(combination, target, rambol) {
    return { combination, target, rambol }
  }

  const Bet3DtoPrint = (combination, drawcode, betamount) => {
    let code = drawcode.split("-")[1]
    console.log(betstoprint)
    console.log(code)
    if (code == "S3r") {
      let com1 = CheckcomtoPrint(combination)
      // --- if triple number dont add on rambol
      if (com1.bool) {
        let updatedData = [...betstoprint]
        let SelectRowBet = betstoprint[com1.index]
        let TotalBets = parseInt(betamount) + parseInt(SelectRowBet.rambol)
        updatedData[com1.index].rambol = TotalBets
      } else {
        betstoprint.push(createDatatoPrint(combination, 0, betamount))
      }
    } else if (code == "S3") {
      // let com1 = CheckcomtoPrint(combination)
      // if (com1.bool) {
      //   let updatedData = [...betstoprint]
      //   let SelectRowBet = betstoprint[com1.index]
      //   let TotalBets = parseInt(betamount) + parseInt(SelectRowBet.target)
      //   updatedData[com1.index].target = TotalBets
      // } else {
      betstoprint.push(createDatatoPrint(combination, betamount, 0))
      // }
    }
  }

  const onClickBet = async (row) => {
    let data = {
      usercode: localStorage.getItem("usercode"),
      date: todayDate,
      transactionid: row.transactionid,
    }
    setSelectedTrans(row.transactionid)
    // if (version == "v8.18" || version == "v8.19") {
    setQrRef("7XjbALGDX1XKPOH3pp1qSF/QYRGXiBrSWWCkRKVjhDikHWjZ")
    // } else {
    //   setQrRef(row.transactionid.substring(0, 12))
    // }

    let result = await GetBets(data)
    // console.log(result)
    setBetsclick(result)
    setModalShow(true)
    setSelectedTime(result[0].drawcode.split("-")[0])
  }

  useEffect(() => {
    LoadBets(timeselected)
    setTriger("")
    // let total = 0
    // acceptedbets.forEach(a (row) => {
    //   total += parseInt(row.totalbet)
    //   // console.log(row.totalbet)
    // })
    // setTotalGross(12000)
    // Pinterstat()
  }, [triger])
  return (
    <div>
      {user == null ? <Navigate to="/" replace={true} /> : null}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="card">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "left",
            marginLeft: "20px",
            top: 30,
          }}
        >
          <FormControl
            fullWidth
            sx={{
              mx: 1,
              width: "25%",
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              htmlFor="uncontrolled-native"
              size="small"
            >
              Time
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={timeselected}
              label="Select Game"
              onChange={(e) => {
                setTimeSelected(e.target.value)
                LoadBets(e.target.value)
              }}
            >
              {drawtime.map((row, i) => (
                <MenuItem key={i} value={row}>
                  {row}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <label
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              color: "gray",
              fontSize: "20px",
            }}
          >
            TOTAL GROSS : {totalgross}
          </label>
        </Box>
        <Box
          sx={{
            // display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 70,
          }}
        >
          <DataTable
            columns={columns}
            data={acceptedbets}
            onRowClicked={(row) => onClickBet(row)}
            // paginationPerPage={5}
            // paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            highlightOnHover
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            fixedHeader
            pagination
          />
        </Box>
      </div>
      <Box
        component="form"
        sx={{
          // mx: 2,
          // display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "right",
          bottom: 70,
        }}
      >
        <InputLabel sx={{ mx: 2 }}>
          <b>Printer Status:</b>
          <Pinterstat />
        </InputLabel>
      </Box>
    </div>
  )
}
export default AcceptedBetsCeb
